import "./vitrine2.css";
import foto from "../../assets/modelos.png";

function Vitrine2(){
    return <div className="vitrine-box text-center">
        <img src={foto} alt="Foto" />
        <div>
            <h2>Produto 03</h2>
            <p className="prod-vitrine-descricao">descrição simples</p>
        </div>
        <div>
            <a href="#" className="btn btn-dark btn-lg btn-banner">Fazer um orçamento</a>
        </div>
    </div>
}

export default Vitrine2;