import "./navbar.css";
import imgfundo from "../../assets/energia03.png";

function Navbar(){

    return <section className="banner">

            <div className="row">

            <div className="col-lg-6">
                    <img src={imgfundo} alt="SollarNow"/>
            </div>

                <div className="col-lg-6 text-center">
                    <br/>
                    <h1>Quer fazer parte da nossa equipe?</h1>
                    <br/>
                    <h4>Ganhe saúde e dinheiro.</h4>
                    <br/>
                    <br/>
                    <a href="#" className="btn btn-dark btn-lg btn-banner">Seja um associado</a>
                    <a href="#" className="btn btn-outline-light btn-lg btn-banner">Já sou associado</a>
                </div>

           </div>
    </section>;
}

export default Navbar;