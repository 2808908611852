import "./marketing.css";

function Marketing(){
    return <div className = "container">

                 <div>
                     <br />
                     <h2 className="economize text-center">Mantenha sua saúde mental e corporal em 99%</h2>
                     <h2 className="conta text-center">Conseguimos fazer o seu dia-a-dia mais saudável</h2>
                     <h2 className="economize text-center">Com nossos produtos você ficará mais feliz!!!</h2> 
                     <br />
                 </div>
        
           </div>;
}

export default Marketing
